import { IEnvironment } from './environment';

export const environmentDefault: IEnvironment = {
    alternativeLogo: false,
    // TODO: apiUrl is deprecated. Use apiBaseUrl instead.
    apiUrl: 'https://api.erg-1.zelcore.io/api/v0',
    apiBaseUrl: 'https://api.erg-1.zelcore.io',
    apiUrlV1: 'https://api.erg-1.zelcore.io/api/v1',
    blockchain: {
        coinName: 'Erg',
    },
    defaultLocale: 'en',
    environments: [
        {
            name: 'Mainnet',
            url: 'https://api.erg-1.zelcore.io',
        },
    ],
    isLoggerEnabled: true,
};
